








































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { Watch } from 'vue-property-decorator';
import Concentrations from 'common-modules/src/store/Concentrations';
import { RunConcentration } from 'common-modules/src/store/interface/RunConcentration';
import { RunCourse } from 'common-modules/src/store/interface/RunCourse';
import { RunUnit } from 'common-modules/src/store/interface/RunUnit';
import TaskIcon from '@/components/TaskIcon.vue';
import jwlToggle from '@/components/jwlToggle.vue';
import { ExtendedRunConcentration } from '@/store/interface/Concentration';
import JwlButton from '@/components/JwlButton.vue';
import { RunTask } from '@/store/interface/Task';

const IconClock = () => import('common-modules/src/assets/fa-icons/duotone/clock.svg');

@Component({
  components: {
    JwlButton,
    TaskIcon,
    IconClock,
    jwlToggle,
  },
  computed: mapGetters([
    'baseConcentrations',
    'concentration',
  ]),
})
export default class JwlItinerary extends Vue {
  baseConcentrations!: Concentrations;
  concentration!: ExtendedRunConcentration;

  // if currentCourse >= 0 && currentUnit < 0 => course is finished
  // if all are -1 => course not yet started
  currentCourse = -1; // index of the current course based on date
  currentUnit = -1; // index of the current unit based on date
  selectedCourse = -1; // index of the course that is selected
  selectedUnit = -1; // index of the unit that is selected
  openTasks: number[] = [];

  formattedDate (dateString: string, withTime = false): string {
    const date = new Date(dateString);
    return withTime ? date.toLocaleString() : date.toLocaleDateString();
  }

  twoDigit (value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  useDuotone (task: RunTask): boolean {
    return !!task.submitted?.hasSubmitted;
  }

  taskClass (task: RunTask): Record<string, boolean> {
    return {
      'jwl-itinerary__task--filled': this.openTasks.includes(task.id),
    };
  }

  toggleTask (task: RunTask): void {
    if (this.openTasks.includes(task.id)) {
      this.openTasks = this.openTasks.filter((taskId) => taskId !== task.id);
    } else {
      this.openTasks.push(task.id);
    }
  }

  goToPrevious (): void {
    if (this.baseConcentration && this.hasPreviousUnit) {
      if (this.selectedCourse > 0 && this.selectedUnit === 0) {
        this.selectedCourse -= 1;
        this.selectedUnit = this.unitCount - 1;
      } else if (this.selectedUnit > 0) {
        this.selectedUnit -= 1;
      } else if (this.allCoursesFinished && this.selectedUnit < 0) {
        this.selectedUnit = this.unitCount - 1;
      }
    }
  }

  goToNext (): void {
    if (this.baseConcentration && this.hasNextUnit) {
      const nextUnit = this.selectedUnit + 1;
      if (nextUnit >= this.unitCount) {
        if (this.selectedCourse < this.currentCourse) {
          this.selectedCourse += 1;
          this.selectedUnit = 0;
        }
      } else {
        this.selectedUnit = nextUnit;
      }
    }
  }

  showRouterLink (task: RunTask): boolean {
    return this.concentration.role === 'student' && !['track', 'attendance'].includes(task.type);
  }

  @Watch('baseConcentration')
  @Watch('concentration')
  calculateCurrentUnit (): void {
    this.currentCourse = -1;
    this.selectedCourse = -1;
    this.currentUnit = -1;
    this.selectedUnit = -1;
    const now = new Date();
    this.baseConcentration?.courses.forEach((course, courseIndex) => {
      const unitIndex = course.units.findIndex((courseUnit) => {
        const unitStart = new Date(courseUnit.startDate);
        const unitEnd = new Date(courseUnit.endDate);
        return (unitStart <= now && unitEnd >= now);
      });
      if (unitIndex >= 0) {
        this.currentCourse = courseIndex;
        this.selectedCourse = courseIndex;
        this.currentUnit = unitIndex;
        this.selectedUnit = unitIndex;
      } else if (this.currentUnit < 0 && course.startDate) {
        const courseStart = new Date(course.startDate);
        if (courseStart < now) {
          this.currentCourse = courseIndex;
          this.selectedCourse = courseIndex;
        }
      }
    });
  }

  get hasNextUnit (): boolean {
    if (this.selectedCourse < this.currentCourse) {
      return true;
    }
    if (this.allCoursesFinished) {
      if (this.selectedUnit < 0) {
        return false;
      }
      return this.selectedUnit < (this.unitCount - 1);
    }
    return this.selectedUnit < this.currentUnit;
  }

  get hasPreviousUnit (): boolean {
    if (this.selectedUnit > 0) {
      return true;
    }
    if (this.allCoursesFinished && this.selectedUnit < 0) {
      return true;
    }
    return this.selectedUnit <= 0 && this.selectedCourse > 0;
  }

  get baseConcentration (): RunConcentration | undefined {
    const concentrationCode = this.$route.params.concentration;
    return this.baseConcentrations.dataAsFlatArray().find((baseConcentration) => baseConcentration.code === concentrationCode);
  }

  get rootClass (): Record<string, boolean> {
    return {
      'jwl-itinerary--bachelor': this.baseConcentration?.programmeType === 'bachelor',
      'jwl-itinerary--professional': this.baseConcentration?.programmeType === 'professional',
      'jwl-itinerary--teacher-training': this.baseConcentration?.programmeType === 'teacher-training',
      'jwl-itinerary--academy': this.baseConcentration?.programmeType === 'academy',
    };
  }

  get unit (): RunUnit | null {
    if (this.baseConcentration && this.selectedUnit >= 0) {
      return this.baseConcentration.courses[this.selectedCourse].units[this.selectedUnit];
    }
    return null;
  }

  get detailTasks (): RunTask[] | null {
    if (this.concentration) {
      return this.concentration.courses[this.selectedCourse].units[this.selectedUnit].tasks;
    }
    return null;
  }

  get allCoursesFinished (): boolean {
    return this.currentCourse >= 0 && this.currentUnit < 0;
  }

  get noCourseStarted (): boolean {
    return this.currentCourse < 0;
  }

  get courses (): Record<'previous' | 'next', RunCourse[]> {
    const previous: RunCourse[] = [];
    const next: RunCourse[] = [];

    this.baseConcentration?.courses.forEach((course, courseIndex) => {
      if (courseIndex <= this.selectedCourse) {
        previous.push(course);
      } else {
        next.push(course);
      }
    });

    return {
      previous,
      next,
    };
  }

  get unitCount (): number {
    return this.baseConcentration
      ? this.baseConcentration.courses[this.selectedCourse].units.length
      : 0;
  }

  created (): void {
    this.calculateCurrentUnit();
  }
}
